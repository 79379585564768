@import url(./assets/styles/fonts.css);

$main-grey: #191c24;
$light-grey: #7a7c81;

body {
  font-family: 'Rubik';
  color: white;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: $main-grey;
  width: 100%;
  height: 100vh;
}

.t {
  border: 2px solid grey;
  background-color: rgba($color: #000000, $alpha: 0.2);
}

.navbar {
  height: 40px;
  background-color: black;
}

.container {
  display: flex;
  flex-grow: 1;
  padding-top: 4px;
  
  .sidebar {
    display: flex;
    flex-direction: column;
    
    flex-shrink: 0;
    width: 250px;
    padding: 4px;

    background-color: $main-grey;
    
    .nav-item {
      position: relative;
      display: flex;

      color: $light-grey;
      
      border-radius: 0 10px 10px 0;
      padding: 8px;
      margin-bottom: 2px;
      transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.2);
        border-left: 6px solid green;
      }

      .nav-title {
        justify-self: center;
        align-self: center;
      }

      i {
        font-size: 24px;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        padding: 6px;
        background-color: rgba($color: #fff, $alpha: 0.1);
        border-radius: 50%;
      }

      .nav-arrow {
        position: absolute;
        right: 0;
      }

    }

    .active {
      background-color: rgba($color: #000000, $alpha: 0.2);
      border-left: 6px solid blueviolet;
    }

    .nav-sub {
      display: flex;
      flex-direction: column;

      overflow: hidden;
      align-items: flex-start;
      padding: 0 4px 4px 48px;
      transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
  
      a {
        text-decoration: none;
        color: #7a7c81;
        padding: 8px 0 8px 8px;
        text-align: left;
        width: 100%;
        border-radius: 8px 0 0 8px;

        &:hover {
          color: white;
          background-color: rgba($color: #000000, $alpha: 0.2);
        }
      }
    }

    .username {
      position: absolute;
      bottom: 16px;
      align-self: center;
      color: $light-grey;
    }

    .nav-sub-close {
      animation: 1s nav-sub-close;
      animation-fill-mode: forwards;
    }
  
    .nav-sub-open {
      animation: 1s nav-sub-open;
      animation-fill-mode: forwards;
    }
  
    @keyframes nav-sub-open {
      0% {
        height: 0px;
        visibility: visible;
      }
      100% {
        height: 100px;
      }
    }
  
    @keyframes nav-sub-close {
      0% {
        height: 100px;
      }
      100% {
        height: 0px;
        visibility: hidden;
      }
    }
  }
  
  .content {
    display: flex;
    align-content: flex-start;
    flex-grow: 1;

    padding: 16px;
    gap: 16px;
    overflow: scroll;
    flex-wrap: wrap;
        
    background-color: black;
    border-radius: 8px;
    
    div {
      display: flex;
      flex-shrink: 1;
      flex-grow: 1;

      height: fit-content;
      min-height: 100px;
      min-width: 300px ;

      padding: 16px;
      background-color: $main-grey;

      border-radius: 8px;
    }
  }
}