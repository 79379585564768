@font-face {
  font-family: 'Assistant';
  src: url('../fonts/Assistant/Assistant-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: light;
}

@font-face {
  font-family: 'Assistant';
  src: url('../fonts/Assistant/Assistant-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: regular;
}

@font-face {
  font-family: 'Assistant';
  src: url('../fonts/Assistant/Assistant-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: bold;
}



@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: light;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: regular;
}

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: bold;
}